<template>
  <li>
    <item-card :url="`/catalog/${item.id}`" contentClass="grid grid-cols-12">
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mb-2 md:mb-0">
          {{ item.name }}
        </h3>
          <template v-if="item.is_services">
              <Badge text="Pakalpojums" className="bg-indigo-100 text-indigo-800 mr-2 mb-2 md:mb-0" />
          </template>
      </template>
      <template v-slot:buttons>
        <template v-if="userHavePermission('manage_catalog_items') && item.allow_delete">
          <Button icon="delete" @click="deleteCatalogItem(item.id)" />
        </template>
      </template>

      <template v-slot:content>

        <div class="col-span-12 sm:col-span-6 lg:col-span-2">
          <item-text title="Preces kods" :text="item.sku" />
        </div>

        <div class="col-span-12 sm:col-span-6 lg:col-span-2">
          <item-text title="Mērvienība" :text="item.measure_unit.name" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-2">
          <item-text title="Kategorija" :text="item.category" />
        </div>

        <template v-if="item.price > 0">
          <div class="col-span-12 sm:col-span-6 lg:col-span-2">
            <item-text title="Cena bez PVN / EUR" :text="parseFloat(item.price).toFixed(2)" />
          </div>
        </template>

        <template v-if="item.image_url">
          <div class="col-span-12 sm:col-span-6 lg:col-span-2">
            <item-text>
              <template v-slot:content>
                <img :src="item.image_url" :alt="item.name" class="max-w-[75px] max-h-[75px] w-full object-contain">
              </template>
            </item-text>
          </div>
        </template>
      </template>

      <template v-slot:additional v-if="item.notes">
        <div class="col-span-12">
          <div class="block border-t border-gray-200 dark:border-gray-700">
            <div class="pt-4">
              <div class="sm:flex flex-wrap">
                <ItemText title="Piezīmes" :text="item.notes" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </item-card>
  </li>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Badge from "../../../../Components/Badge";

export default {
  name: "CatalogListItem",
  components: {
    ItemCard,
    ItemText,
      Badge
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  methods: {
    userHavePermission(permission) {
      return this.$userCan(permission)
    },
    viewCatalogItem(catalogItemId) {
      this.$router.push({path: '/catalog/' + catalogItemId})
    },
    deleteCatalogItem(itemId) {
      if (confirm('Vai esi drošs?')) {
        this.$Progress.start()
        this.$store.dispatch('deleteCatalogItem', itemId)
      }
    },
  }
}
</script>

<style>
</style>
<template>
    <Modal :size="'md'" :showModal="showModal">
        <template v-slot:button>
            <div class="flex h-full items-center">
                <button
                    @click="showModal = true"
                    class="
            rounded-full
            w-12
            h-12
            bg-gray-200
            dark:bg-main4
            flex
            justify-center
            items-center
            leading-none
            hover:bg-gray-300
            transition
            duration-300
            ease-in-out
            dark:text-gray-100
          "
                >
                    <PlusIcon class="w-7 h-7"/>
                </button>
            </div>
        </template>

        <template v-slot:content>
            <form class="space-y-6 w-full">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">

                    <div class="col-span-1">
                        <Select
                            placeholder="Mērvienība"
                            :items="inputData.measure_units"
                            v-model="form.measureUnit"
                            :errors="errors.measureUnit"
                        />
                    </div>


                    <div class="col-span-1">
                        <Select
                            placeholder="Kategorija"
                            v-model="form.category"
                            :errors="errors.category"
                            :items="inputData.categories"
                        />
                    </div>

                    <div class="col-span-1">
                        <Input
                            name="name"
                            placeholder="Nosaukums"
                            v-model="form.name"
                            :errors="errors.name"
                        />
                    </div>

                    <template v-if="form.category && form.category.options">
                        <template
                            v-for="(type, index) in form.category.options"
                            :key="index"
                        >
                            <template v-if="type.length > 0">
                                <template v-if="index === 'without_type'">
                                    <div class="col-span-1">
                                        <Select
                                            :placeholder="option.name"
                                            :items="option.items"
                                            v-model="form.categoryOptionsWithoutType[index]"
                                        />
                                    </div>
                                </template>

                                <template v-else>
                                    <template v-for="(option, index) in type" :key="index">
                                        <div class="col-span-1">
                                            <div>
                                                <p>
                                                    {{ option.name }}
                                                </p>
                                            </div>
                                            <div class="flex items-center">
                                                <Checkbox
                                                    :text="item.name"
                                                    :value="item"
                                                    v-model="form.categoryOptionsWithType"
                                                />
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </template>

                    <div class="col-span-1">
                        <Input
                            name="basePrice"
                            placeholder="Pārdošanas bāzes cena / EUR"
                            v-model="form.basePrice"
                            :errors="errors.basePrice"
                        />
                    </div>

                    <div class="col-span-1">
                        <Input
                            name="itemValue"
                            placeholder="Pašizmaksa / EUR"
                            v-model="form.itemValue"
                            :errors="errors.itemValue"
                        />
                    </div>

                    <div class="col-span-1">
                        <Input
                            name="itemValue"
                            placeholder="Noklusējuma reģionālais kods"
                            v-model="form.defaultRegionalCode"
                            :errors="errors.defaultRegionalCode"
                        />
                    </div>

                    <div class="col-span-1 flex items-center">
                        <Checkbox text="Pakalpojums" v-model="form.is_services"/>
                    </div>


                    <div class="col-span-1">
                        <Select
                            placeholder="Piegādātājs"
                            v-model="form.supplier"
                            :errors="errors.supplier"
                            :items="inputData.suppliers"
                            :clearAble="true"
                        />
                    </div>

                    <template v-if="form.supplier && form.supplier.id">
                        <div class="col-span-1">
                            <Input
                                name="basePrice"
                                placeholder="Piegādātāja kods"
                                v-model="form.supplier_external_id"
                                :errors="errors.supplier_external_id"
                            />
                        </div>
                    </template>

                </div>

                <div class="">
                    <div class="flex justify-end">
                        <button
                            type="button"
                            class="
                bg-white
                dark:bg-gray-750
                py-2
                px-4
                border border-gray-300
                dark:border-gray-500
                rounded-md
                shadow-sm
                text-sm
                font-medium
                text-gray-700
                dark:text-gray-300
                hover:bg-gray-50
                dark:hover:bg-gray-770
                focus:outline-none focus:ring-0
                mr-2
              "
                            @click="hideAddItemForm"
                        >
                            Atcelt
                        </button>

                        <template v-if="!loading">
                            <button
                                type="button"
                                class="
                  flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-white
                  bg-gradient-to-r
                  from-button2-from
                  to-button2-to
                  hover:from-button2-to hover:to-button2-from
                  focus:outline-none focus:border-none
                  transition
                  duration-500
                  ease-in-out
                "
                                @click.prevent="submit"
                            >
                                Pievienot
                            </button>
                        </template>
                        <template v-else>
                            <Loading/>
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/Components/Modal";
import Input from "@/components/Components/Input";
import Select from "@/components/Components/Select";
import Checkbox from "@/components/Components/Checkbox";
import {mapGetters} from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import {
    PlusIcon,
} from "@heroicons/vue/solid";

export default {
    name: "AddCatalogItem",
    components: {
        Modal,
        Input,
        Select,
        Checkbox,
        Loading,
        PlusIcon,
    },
    props: ["inputData"],
    data: () => ({
        form: {
            sku: "",
            name: "",
            category: null,
            measureUnit: null,
            is_services: false,
            basePrice: "",
            itemValue: "",
            defaultRegionalCode: "",
            supplier: null,
            supplier_external_id: ''
        },
        showModal: false,
    }),
    computed: {
        ...mapGetters({
            loading: "loading",
            errors: "errors",
        }),
        formValidation() {
            return {
                measureUnit: {
                    rules: ["required"],
                },
                category: {
                    rules: ["required"],
                },
                name: {
                    rules: ["required"],
                },
                supplier_external_id: {
                    rules: this.form.supplier && this.form.supplier.id ?  ["required"] : []
                }
            };
        },
    },
    watch: {
        showModal() {
            this.form = {
                name: "",
                category: null,
                measureUnit: null,
                isForRent: false,
                is_services: false,
                basePrice: "",
                itemValue: "",
            };
        },
    },
    methods: {
        hideAddItemForm() {
            this.$store.dispatch("removeAllFormsForDisplay");
            this.showModal = false;
        },
        submit() {
            this.$Progress.start();
            if (Validation(this.formValidation, this.form)) {
                this.$store
                    .dispatch("createNewCatalogItem", {
                        data: {
                            name: this.form.name,
                            measure_unit_id: this.form.measureUnit.id,
                            base_price: this.form.basePrice,
                            item_value: this.form.itemValue,
                            is_services: this.form.is_services,
                            default_regional_code: this.form.defaultRegionalCode,
                            supplier_id: this.form.supplier ? this.form.supplier.id : null,
                            external_id: this.form.supplier_external_id ? this.form.supplier_external_id : null
                        },
                        categoryId: this.form.category.id,
                    })
                    .then(() => {
                        this.showModal = false;
                    });
            } else {
                this.$Progress.fail();
            }
        },
    },
};
</script>